<!--
 * @Author: your name
 * @Date: 2020-12-18 15:11:48
 * @LastEditTime: 2021-02-07 14:21:34
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/Setting/changeNewPhone.vue
-->
<!--
 * @Author: your name
 * @Date: 2020-10-19 17:24:56
 * @LastEditTime: 2020-12-18 15:01:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nethallofficialaccounts\src\views\Setting\changeCurrenrPhone.vue
-->
<template>
  <div class="changephone-page">
    <div>
      <div class="changephone-header">
        <p>填写新手机号</p>
        <span>请填写您要绑定的新手机号并进行短信验证</span>
      </div>
      <div class="changephone-content">
        <van-form>
          <van-field
            label="新手机号"
            placeholder="请填写"
            v-model="nextPhone"
            clearable
            type="digit"
            maxlength="11"
            :rules="[{ validator: validatorPhone, message: '' }]"
          />
          <van-field
            label="验证码"
            placeholder="填写验证码"
            center
            clearable
            v-model="nextCode"
            :maxlength="6"
          >
            <template #button>
              <van-button
                size="mini"
                type="default"
                class="sms-button"
                @click="getCode"
                :disabled="isDisabled"
                >{{ buttonContent }}</van-button
              >
            </template>
          </van-field>
        </van-form>
      </div>
      <div class="nextBtn">
        <van-button type="info" block @click="submitCode">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { isMobile } from '@/utils/validator';
import { getCode, updateMobile } from '../../api/home';

export default {
  name: 'ChangeNewPhone',
  data() {
    return {
      isDisabled: false,
      buttonContent: '获取验证码',
      wait: 60,
      code: '',
      nextFlag: true, // 下一步的页面 true 当前页面 fakse 下一个页面
      nextCode: '',
      nextPhone: ''
    };
  },
  computed: {
    ...mapState('user', {
      pathUrl: (state) => state.pathUrl
    })
  },
  methods: {
    validatorPhone(val) {
      return isMobile(val);
    },
    async getCode() {
      if (this.validatorPhone(this.nextPhone)) {
        this.getSendMessage();
        let arg = {
          phone: this.nextPhone
        };
        const { status, resultData } = await getCode(arg);
        if (status === 'complete') {
          Toast('发送成功');
        } else {
          Toast('发送失败');
        }
      } else {
        Toast('请填写正确的手机号');
      }
    },
    getSendMessage() {
      if (this.wait === 0) {
        this.isDisabled = false;
        this.buttonContent = '重新获取验证码';
        this.wait = 60;
      } else {
        this.isDisabled = true;
        this.buttonContent = this.wait + 's后重新获取';
        this.wait--;
        setTimeout(() => {
          this.getSendMessage();
        }, 1000);
      }
    },
    async submitCode() {
      if (!this.nextCode) {
        return Toast('请输入验证码');
      } else if (!this.nextPhone) {
        return Toast('请输入手机号');
      }
      let arg = {
        code: this.nextCode,
        phone: this.nextPhone
      };
      const { status, resultData } = await updateMobile(arg);
      if (status === 'complete') {
        Toast('手机号码修改成功');
        if (this.pathUrl && this.pathUrl.url) {
          this.$router.push({ path: this.pathUrl.url });
        } else {
          this.$router.push({ name: 'Setting', path: '/Setting' });
        }
      }
    }
  },
  destroyed() {
    // 出去清除路由里面的跳转状态
    store.dispatch('user/savePathUrl', {});
  }
};
</script>

<style lang="less" scoped>
.changephone-page {
  background: #ffffff;
  .changephone-header {
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 36px;
    p {
      margin: 0px;
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #1c2734;
      line-height: 26px;
      font-weight: 700;
      padding-bottom: 5px;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #3f3f3f;
      line-height: 26px;
    }
  }
  .changephone-content {
    .sms-button {
      color: #999999;
      border: 1px solid #999999;
      border-radius: 100px;
      color: #4466ad;
      border: 1px solid #4466ad;
      .van-button__text {
        font-size: 12px;
      }
    }
    .van-button {
      border-radius: 4px;
    }
    /deep/.van-cell__title {
      span {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
      }
    }
    /deep/.van-field__control {
      display: inline-block;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #5e6268;
    }
    /deep/.van-field__right-icon {
      font-size: 16px;
    }
  }
  .nextBtn {
    margin-top: 135px;
    padding-bottom: 149px;
    margin-left: 16px;
    margin-right: 16px;
    .van-button {
      border-radius: 8px;
    }
  }
}
</style>
